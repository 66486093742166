import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { SaldoVirtualService } from '../../saldo-virtual/saldo-virtual.service';
import { AlertService } from '../../shared/alert.service';
import { ComponentBase } from '../../shared/component-base';
import { SessionService } from '../../shared/session.service';
import { ConsultarProdutoEstoqueVirtualResponseEntity } from '../entities/response/consultar-produto-estoque-virtual-response.entity';
import { ProdutoService } from '../produto.service';

@Component({
  selector: 'app-produto-transferencia-saldo',
  templateUrl: './produto-transferencia-saldo.component.html',
  styleUrls: ['./produto-transferencia-saldo.component.css']
})
export class ProdutoTransferenciaSaldoComponent extends ComponentBase implements OnInit {
  produtosEstoqueVirtual: ConsultarProdutoEstoqueVirtualResponseEntity[] = [];
  idConta: number;
  idDivisaoOrigem: number;
  idDivisaoDestino: number;
  qtdTransferir: number = 0;
  idPessoaOrigem: number;
  idPessoaDestino: number;
  idProduto: number;
  totalDisponivel: number;

  constructor(
    private saldoVirtualService: SaldoVirtualService,
    private produtoService: ProdutoService,
    public session: SessionService,
    protected alert: AlertService,
    protected router: Router
  ) { super(); }

  ngOnInit() {

    if (!this.session.empresa && !this.session.usuario.agruparDivisao) {
      this.alert.warning("Você precisa selecionar uma empresa para realizar a distribuição virtual de produtos.")
        .then(() => {
          this.session.urlReturn = window.location.href;
          this.router.navigate(['empresa/selecionar']);
        });

      return;
    }
  }

  selecionarDivisao(divisaoId: number) {
    this.loading2<ConsultarProdutoEstoqueVirtualResponseEntity[]>(() => this.produtoService.consultarProdutoEstoqueVirtual(this.session.empresa.idCliente, divisaoId))
      .pipe(take(1))
      .subscribe((resp) => {
        if (resp.length === 0)
          this.alert.info("Nenhum usuário da divisão selecionada possui estoque virtual alocado.");
        else {
          this.produtosEstoqueVirtual = resp;
        }
      });
  }

  selecionarProduto(idProduto: number) {
    this.totalDisponivel = this.produtosEstoqueVirtual.find(p => p.idProduto == idProduto).saldo;
    this.idProduto = idProduto;
  }

  transferir() {
    if (this.totalDisponivel < this.qtdTransferir) {
      this.alert.warning("A quantidade a ser transferida não pode ser maior que o saldo disponível.");
      return;
    }

    this.alert.confirm("Deseja transferir o saldo deste produto?")
      .then((result) => {
        if (result.value) {
          this.loading2(() => this.produtoService.devolverProdutoEstoqueVirtual(this.session.empresa.idCliente, this.idDivisaoOrigem, this.idProduto, this.qtdTransferir))
            .pipe(take(1))
            .subscribe(() => {
              this.alert.success("Estoque atualizado com sucesso!")
                .then(() => {
                  this.router.navigate(['produtos/transferir-saldo-divisao']);
                });
            });
        }
      });
  }
}
