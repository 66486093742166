import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { take } from 'rxjs/operators';
import { DivisaoESLEntity } from '../entities/divisao-esl.entity';
import { EmpresaEntity } from '../entities/empresa.entity';
import { AlertService } from '../shared/alert.service';
import { ComponentBase } from '../shared/component-base';
import { SessionService } from '../shared/session.service';
import { DivisaoService } from './divisao.service';

const noop = () => {
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DivisaoComponent),
  multi: true
};


@Component({
  selector: 'app-divisao',
  templateUrl: './divisao.component.html',
  styleUrls: ['./divisao.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class DivisaoComponent extends ComponentBase implements ControlValueAccessor, OnInit {
  empresas: EmpresaEntity[] = [];
  empresaSelecionada: string;
  permiteSelecionarEmpresa: boolean = false;
  _divisoes: Array<DivisaoESLEntity>;
  loadingDivisoes: boolean = true;
  agruparDivisao: boolean = false;

  @Input() apenasAprovacaoPedido: boolean = false;
  @Input() apenasConsultaPedido: boolean = false;
  @Input() idDivisaoPadrao: number = 0;
  @Input() disabled: boolean = false;
  @Input() apenasUsuarioMaster: boolean = false;
  @Input() multiple: boolean = true;
  @Input() permitirSelecaoUnicaDivisao: boolean = false;
  @Input() label: string;
  @Input() permiteAcessoTotal: boolean = false;
  @Input() somenteComProdutosEmEstoque: boolean = false;
  @Input() idProduto: number; // Lista somente as divisões que contenham um determinado produto.
  @Input() placeHolder: string = "Todas as divisões";

  @Output() onSelecionarEmpresa: EventEmitter<string> = new EventEmitter();
  @Output() onSelecionarDivisao: EventEmitter<any> = new EventEmitter();

  constructor(
    private session: SessionService,
    private divisaoService: DivisaoService,
    protected alert: AlertService
  ) {
    super();
  }

  ngOnInit() {
    this.empresas = this.session.usuario.empresas;
    this.agruparDivisao = this.session.usuario.agruparDivisao;

    if ((this.permiteAcessoTotal || this.possuiAcessoRecurso(80)) && !this.agruparDivisao)
      this.carregarTodasAsDivisoes();
    else
      this.carregarDivisoes();

    this.permiteSelecionarEmpresa = this.session.empresa == null;
    if (this.divisoes && this.divisoes.length === 1) {
      setTimeout(() => {
        this.writeValue(this.divisoes[0].idPessoaDivisao.toString());
      }, 1000);
    }

    // Bloqueia a seleção de empresas caso as divisões sejam agrupadas.
    if (this.agruparDivisao) {
        this.permiteSelecionarEmpresa = false;
    }

    if (!this.label)
      this.label = this.multiple ? 'Divisões' : 'Divisão';
  }

  carregarTodasAsDivisoes() {
    if (!this.session.empresa && !this.empresaSelecionada)
      return;

    if (this.session.empresa) {
      this.loading2<DivisaoESLEntity[]>(() => this.divisaoService.consultarDivisaoEmpresa(this.session.empresa.idCliente, this.somenteComProdutosEmEstoque))
        .pipe(take(1))
        .subscribe((resp => {
          this.divisoes = resp;
          this.ajustarDivisoes();
          this.loadingDivisoes = false;
        }))
    }
    else {
      this._divisoes = [];
      const clientes = this.obterEmpresaSelecionada().split(',');

      for (let i = 0; i < clientes.length; i++) {
        this.loadingDivisoes = true;
        this.call2<DivisaoESLEntity[]>(() => this.divisaoService.consultarDivisaoEmpresa(Number(clientes[i]), this.somenteComProdutosEmEstoque))
          .pipe(take(1))
          .subscribe((resp => {
            this.divisoes = this._divisoes.concat(resp);
            this.ajustarDivisoes();
            this.loadingDivisoes = false;
          }))
      }
    }
  }

  carregarDivisoes() {

    this.divisoes = [];

    //Carrega divisao padrão caso a mesma seja especificada.
    if (this.idDivisaoPadrao > 0)
      this.divisoes.push(this.session.divisoes.find(x => x.idPessoaDivisao == this.idDivisaoPadrao));

    //Carrega as divisoes de usuario Master
    else if (this.apenasUsuarioMaster)
      this.divisoes = this.session.divisoesMaster;

    //Carrega as divisoes onde o usuario pode aprovar ou visualizar pedidos.
    else if (this.apenasAprovacaoPedido && this.apenasConsultaPedido)
      this.divisoes = this.session.divisoesConsultaAprovaPedido;
    else if (this.apenasAprovacaoPedido && !this.apenasConsultaPedido)
      this.divisoes = this.session.divisoesAprovaPedido;
    else if (!this.apenasAprovacaoPedido && this.apenasConsultaPedido)
      this.divisoes = this.session.divisoesConsultaPedido;
    else
      this.divisoes = this.session.divisoes;

    if (this.divisoes.length === 1) {
      this.selectValue = this.divisoes[0].idPessoaDivisao.toString();
      this.onSelecionarDivisao.emit(this.divisoes[0].idPessoaDivisao);
    }

    this.ajustarDivisoes();
    this.loadingDivisoes = false;
  }

  selecionarEmpresa() {

    this.selectValue = null;

    if (this.permiteAcessoTotal || this.possuiAcessoRecurso(80))
      this.carregarTodasAsDivisoes();
    else
      this.carregarDivisoes();

    this.onSelecionarEmpresa.emit(this.empresaSelecionada);
  }

  obterEmpresaSelecionada(): string {
    if (this.empresaSelecionada == null)
      return null;

    return this.session.usuario.empresas.find(x => x.nomeCliente.toLowerCase() === this.empresaSelecionada.toLowerCase()).idCliente.toString();
  }

  selecionarDivisao() {
    if (this.selectValue) {
      this.onSelecionarDivisao.emit(this.selectValue);
    }
  }

  //The internal data model
  private innerSelectValue: any = '';

  //Placeholders for the callbacks which are later providesd
  //by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  //get accessor
  get selectValue(): any {
    if (this.divisoes && this.divisoes.length === 1)
      return this.divisoes[0].idPessoaDivisao.toString();

    return this.innerSelectValue;
  }

  //set accessor including call the onchange callback
  set selectValue(value: any) {
    if (value !== this.innerSelectValue) {
      this.innerSelectValue = value;
      this.onChangeCallback(value);
    }
    this.onTouchedCallback();
  }

  //From ControlValueAccessor interface
  writeValue(value: any) {

    if (value !== this.innerSelectValue)
      this.innerSelectValue = value;
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  ajustarDivisoes() {
    if (this.empresaSelecionada) {
      if (!this.permiteAcessoTotal && !this.possuiAcessoRecurso(80))
        this.divisoes = this.divisoes.filter(x => x.empresa.toLowerCase() == this.empresaSelecionada.toLowerCase());

      if (this.divisoes.length === 1) {
        let divisao = new Array<string>();
        divisao.push(this.divisoes[0].idPessoaDivisao.toString());
        this.selectValue = divisao;
      }
    }
  }

  set divisoes(divisoes: DivisaoESLEntity[]) {
    this._divisoes = divisoes;
  }

  get divisoes(): DivisaoESLEntity[] {
    if (!this._divisoes || this._divisoes.length === 0) {
      const ret = new Array<DivisaoESLEntity>();
      let obj = new DivisaoESLEntity();
      obj.idPessoaDivisao = 0;
      if (!this.session.empresa && !this.empresaSelecionada)
        obj.nomeDivisao = "Selecione uma empresa";
      else if (this.somenteComProdutosEmEstoque)
        obj.nomeDivisao = "Sem divisões com estoque";
      else
        obj.nomeDivisao = "Sem divisões cadastradas";

      ret.push(obj);
      return ret;
    }

    return this._divisoes;
  }

  get idsDivisoes() : number[]{

    if (this.innerSelectValue && this.agruparDivisao){
      if (this.multiple){
        const ids:number[] =[];
        for(let x=0; x< this.innerSelectValue.length; x++){
          const _ids = this.innerSelectValue[x].split(',');
          ids.push(... _ids);
        }

        return ids;
      }
      else{
        return this.innerSelectValue.split(',').map(x => parseInt(x));
      }
    }

    return [];
  }

}
