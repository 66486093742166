import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { concatMap, take } from 'rxjs/operators';
import { DivisaoESLEntity } from '../entities/divisao-esl.entity';
import { FichaProdutoEntity } from '../entities/ficha-produto.entity';
import { ProdutoEntity } from '../entities/produto.entity';
import { AlertService } from '../shared/alert.service';
import { ComponentBase } from '../shared/component-base';
import { SessionService } from '../shared/session.service';
import { StorageService } from '../shared/storage.service';
import { ConsultarProdutosRelacionadosResponseEntity } from './entities/response/consultar-produto-relacionados-response.entity';
import { TagProdutoEntity } from './entities/tag-produto.emtity';
import { TransferenciaProdutoDivisaoRequestEntity } from './entities/transferencia-produto-divisao-request.entity';
import { ProdutoService } from './produto.service';
import { DivisaoService } from '../divisao/divisao.service';
import { DivisaoEntity } from '../cadastro/entities/divisao.entity';
import { from } from 'rxjs';

@Component({
  selector: "app-produto",
  templateUrl: "./produto.component.html",
  styleUrls: ["./produto.component.css"],
})
export class ProdutoComponent extends ComponentBase implements OnInit {
  modoTransferencia: boolean = false;
  divisoesTransferencia: DivisaoEntity[] = [];
  modoConferencia: boolean = false;
  transferindo: boolean = false;

  @Input() pesquisarSaldoZerado: boolean = false;
  @Input() idDivisaoPadrao: number = 0;
  @Input() controleVisualizacao: string = "card";
  @Input() addProdutos: boolean = false;
  @Input() showControleVisualizacao: boolean = false;
  @Input() simpleView: boolean = true;
  @Input() exibirSaldoVirtual: boolean = false;
  @Input() carregamentoAutomatico: boolean = false;

  @Output() onAddRemove: EventEmitter<ProdutoEntity> = new EventEmitter();

  @Input() produtos: ProdutoEntity[];
  produtoSelecionado: ProdutoEntity;
  divisaoOrigem: DivisaoESLEntity = new DivisaoESLEntity();
  transferenciaProdutoDivisaoRequestEntity: TransferenciaProdutoDivisaoRequestEntity =
    new TransferenciaProdutoDivisaoRequestEntity();

  private modal: NgbModalRef;

  @ViewChild("modalProduto", { static: true })
  modalProdutoContentRef: TemplateRef<any>;
  @ViewChild("modalLocalizacao", { static: true })
  modalLocalizacaoRef: TemplateRef<any>;
  @ViewChild("modalDivisao", { static: true })
  modalDivisaoRef: TemplateRef<any>;
  @ViewChild("modalAjusteEstoque", { static: true })
  modalAjusteEstoqueRef: TemplateRef<any>;

  constructor(
    private session: SessionService,
    protected modalService: NgbModal,
    private produtoService: ProdutoService,
    protected alert: AlertService,
    protected router: Router,
    private storage: StorageService,
    private divisaoService: DivisaoService
  ) {
    super();
  }

  ngOnInit() {
    if (
      this.addProdutos ||
      window.location.href.indexOf("estoque/transferencia-divisao") > -1 ||
      window.location.href.indexOf("produtos/catalogo") > -1
    ) {
      this.showControleVisualizacao = false;
      this.controleVisualizacao = "table";

      // if (!this.session.usuario.agruparDivisao) {
      //   this.redirecionarEmpresa(
      //     `Antes de acessar a transferência entre divisões é necessário selecionar uma empresa.`
      //   );
      // }
    }

    if (this.session.usuario.acessoNovo) this.controleVisualizacao = "table";
  }

  changeViewer(tipo: string) {
    this.controleVisualizacao = tipo;
  }

  onFilterProducts(obj: any) {
    if (obj.item1) {
      this.session.facetProduto = obj.item1;
      this.onSearchProducts(obj.item2);
    } else {
      this.onSearchProducts(obj);
    }
  }

  onSearchProducts(produtos: ProdutoEntity[]) {
    if (produtos.length === 0) {
      if (window.location.href.indexOf("saldo-virtual/distribuir-produto") > -1)
        this.alert.warning(
          "Não existem produtos com saldo para distribuição entre as contas!"
        );
      // else
      //   this.alert.warning("Nenhum produto foi localizado!");
    }

    this.produtos = produtos;
  }

  abrirModal(modal: any) {
    this.modal = this.modalService.open(modal, {
      ariaLabelledBy: "modal-dest",
      windowClass: "medium-modal",
    });
    this.modal.result.then(
      (result) => {},
      (reason) => {}
    );
  }

  carregarProdutosRelacionados(produto: ProdutoEntity) {
    this.loading2<ConsultarProdutosRelacionadosResponseEntity[]>(() =>
      this.produtoService.consultarProdutosRelacionados(produto.idInterno)
    )
      .pipe(take(1))
      .subscribe((resp) => {
        this.session.produtosRelacionados = resp;
      });
  }

  detail(produto: ProdutoEntity) {
    this.produtoSelecionado = produto;
    this.loading2<FichaProdutoEntity>(() =>
      this.produtoService.consultarFichaProduto(
        this.session.usuario["idCliente"],
        produto.idInterno
      )
    )
      .pipe(take(1))
      .subscribe((resp) => {
        this.session.fichaProdutoDetalhe = resp;

        this.carregarTags(produto);
        this.carregarProdutosRelacionados(produto);
      });
  }

  addRemoveProduto(produto: ProdutoEntity) {
    this.onAddRemove.emit(produto);
  }

  onTransferirDivisao(produto: ProdutoEntity) {
    this.produtoSelecionado = produto;
    this.modoTransferencia = true;

    this.loading2<DivisaoEntity[]>(() =>
      this.divisaoService.pesquisarDivisoesUsuario(produto.idProduto)
    )
      .pipe(take(1))
      .subscribe((resp) => {
        this.divisoesTransferencia = resp.filter((x) => x.idDivisao != produto.idDivisao);
        console.log(this.divisoesTransferencia);
      });
  }

  onTransferirLocalizacao(produto: ProdutoEntity) {
    this.produtoSelecionado = produto;
    this.abrirModal(this.modalLocalizacaoRef);
  }

  onMovimentacao(produto: ProdutoEntity) {
    this.produtoSelecionado = produto;
    this.router.navigate([
      "/movimentacao",
      produto.idCliente,
      produto.idInterno,
    ]);
  }

  carregarTags(produto: ProdutoEntity) {
    this.loading2<TagProdutoEntity[]>(() =>
      this.produtoService.consultarTagsPorProduto(produto.idInterno)
    )
      .pipe(take(1))
      .subscribe((resp) => {
        produto.tags = resp;
        this.session.produtoDetalhe = produto;
      });
  }

  voltar() {
    this.modoConferencia = false;
  }

  transferir() {
    if (!this.modoConferencia) {
      if (this.divisoesPreenchidas.length === 0) {
        this.alert.warning(
          "Nenhuma divisão foi selecionada para transferencia de saldo!"
        );
        return;
      }
      this.modoConferencia = true;
      return;
    }

    this.transferindo = true;
    let _divisao: DivisaoEntity;
    from(this.divisoesPreenchidas)
      .pipe(
        concatMap((divisao) => {
          _divisao = divisao;
          this.transferenciaProdutoDivisaoRequestEntity.idCliente = divisao.idCliente;
          this.transferenciaProdutoDivisaoRequestEntity.idDivisaoDestino = divisao.idDivisao;
          this.transferenciaProdutoDivisaoRequestEntity.idDivisaoOrigem = this.produtoSelecionado.idDivisao;
          this.transferenciaProdutoDivisaoRequestEntity.idProduto = this.produtoSelecionado.idProduto;
          this.transferenciaProdutoDivisaoRequestEntity.quantidade = divisao.quantidade;

          return this.loading2(() =>
            this.produtoService.transferirProdutoEntreDivisoes(
              this.transferenciaProdutoDivisaoRequestEntity
            )
          ).pipe(take(1));
        })
      )
      .subscribe({
        next: () => {
          this.produtoSelecionado.estoque -= this.transferenciaProdutoDivisaoRequestEntity.quantidade;
          this.produtoSelecionado.estoqueVirtual -= this.transferenciaProdutoDivisaoRequestEntity.quantidade;
          _divisao.transferido = true;
        },
        error: (err) => {
          console.error('Erro ao transferir produtos:', err);
          this.alert.error("Ocorreu um erro durante a transferência. Tente novamente em alguns instantes. Se o erro persistir entre em contato com o suporte técnico").then(value => {
            if (value) {
              this.finalizar();
            }
          });
        },
        complete: () => {
          this.alert.success("Transferência(s) realizada com sucesso!").then(value => {
            if (value) {
              this.finalizar();
            }
          });
        }
      });
    // for (let divisao of this.divisoesPreenchidas) {
    //   this.transferenciaProdutoDivisaoRequestEntity.idCliente =
    //     divisao.idCliente;
    //   this.transferenciaProdutoDivisaoRequestEntity.idDivisaoOrigem =
    //     divisao.idDivisao;
    //   this.transferenciaProdutoDivisaoRequestEntity.idProduto =
    //     this.produtoSelecionado.idProduto;
    //   this.transferenciaProdutoDivisaoRequestEntity.quantidade =
    //     divisao.quantidade;

    //   this.loading2(() =>
    //     this.produtoService.transferirProdutoEntreDivisoes(
    //       this.transferenciaProdutoDivisaoRequestEntity
    //     )
    //   )
    //     .pipe(take(1))
    //     .subscribe(() => {
    //       this.produtoSelecionado.estoque =
    //         this.produtoSelecionado.estoque -
    //         this.transferenciaProdutoDivisaoRequestEntity.quantidade;
    //       this.produtoSelecionado.estoqueVirtual =
    //         this.produtoSelecionado.estoqueVirtual -
    //         this.transferenciaProdutoDivisaoRequestEntity.quantidade;
    //       divisao.transferido = true;
    //     });
    // }
  }

  finalizar() {
    this.modoConferencia = false;
    this.modoTransferencia = false;
    this.transferindo = false;
    this.divisoesTransferencia = [];
    this.produtoSelecionado = null;
  }

  onAjustarEstoque(produto: ProdutoEntity) {
    this.produtoSelecionado = produto;
    this.storage.data.produtoAjusteEstoque = produto;
    this.abrirModal(this.modalAjusteEstoqueRef);
  }

  calcularSaldoFinal(item: DivisaoEntity) {
    const saldoFinal = this.totalQuantidade;
    if (saldoFinal < 0) {
      item.quantidade = item.saldo * -1;
    }

    if (saldoFinal > this.produtoSelecionado.estoqueVirtual) {
      this.alert.warning("A quantidade informada é maior que o saldo disponível!");
      return;
    }

    item.saldoFinal = item.saldo + item.quantidade;
  }

  get divisoesPreenchidas(): DivisaoEntity[] {
    return this.divisoesTransferencia.filter((x) => x.quantidade > 0);
  }

  get totalQuantidade(): number {
    return this.divisoesPreenchidas.reduce((total, divisao) => total + divisao.quantidade, 0);
  }
}
