import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { take } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CidadeEntity } from '../entities/CidadeEntity';
import { ConsultarPessoaRequestEntity } from "../entities/Requests/consultar-pessoa-request.entity";
import { PessoaEntity } from "../entities/pessoa.entity";
import { UFEntity } from '../entities/uf.entity';
import { CommonService } from '../shared/common.service';
import { ServiceBase } from "../shared/service-base";
import { SessionService } from '../shared/session.service';

@Injectable({
  providedIn: 'root'
})
export class DestinatarioService extends ServiceBase {

  constructor(
    private http: HttpClient,
    private common: CommonService,
    private session: SessionService
  ) { super(); }

  get destinatarios(): PessoaEntity[] {
    var pessoas = JSON.parse(localStorage.getItem('pessoas'));
    if (!pessoas)
      pessoas = [];

    return pessoas;
  }

  set destinatarios(destinatarios: PessoaEntity[]) {
    localStorage.setItem('pessoas', JSON.stringify(destinatarios));
  }


  consultarPessoa(request: ConsultarPessoaRequestEntity): Observable<PessoaEntity[]> {
    return this.http.post<PessoaEntity[]>(`${environment.baseUrl}api/pessoa/ConsultarPessoa`, request);
  }

  listarCidades(idCliente: string): Observable<CidadeEntity[]> {
    const acessoNovo = this.session.usuario.acessoNovo;
    return this.http.get<CidadeEntity[]>(`${environment.baseUrl}api/localidade/ConsultarCidades/${idCliente}/${acessoNovo}`);
  }

  listarUFs(idCliente: string): Observable<UFEntity[]> {
    const acessoNovo = this.session.usuario.acessoNovo;
    return this.http.get<UFEntity[]>(`${environment.baseUrl}api/localidade/ConsultarUFs/${idCliente}/${acessoNovo}`);
  }

  consultarCidades(idCliente: number, idEstado: number): Observable<CidadeEntity[]> {
    const acessoNovo = this.session.usuario.acessoNovo;
    return this.http.get<CidadeEntity[]>(`${environment.baseUrl}api/localidade/ConsultarCidadesPorUF/${idCliente}/${idEstado}/${acessoNovo}`);
  }

  addUsuarioLogadoDestinatario() {
    if (!this.destinatarios)
      this.destinatarios = [];

    if (this.destinatarios.length > 0)
      return;

    const obj = new ConsultarPessoaRequestEntity();
    obj.nome = this.session.usuario.nomePessoa;
    obj.empresas = this.session.getIdsEmpresa();

    this.loading2(() => this.consultarPessoa(obj))
      .pipe(take(1))
      .subscribe((resp: PessoaEntity[]) => {
      if (resp.length > 1) {
        let pessoa = resp.find(x => x.codigoPessoa === this.session.usuario.idPessoa);
        if (pessoa) {
          pessoa.selecionado = true;
          this.addDestinatario(pessoa);
        }
      }
      else if (resp.length === 1) {
        let pessoa = resp[0];
        pessoa.selecionado = true;
        this.addDestinatario(pessoa);
      }
    });
  }


  addRemoveDestinatario(destinatario: PessoaEntity) {

    if (destinatario.selecionado)
      this.removeDestinatario(destinatario);
    else
      this.addDestinatario(destinatario);
  }

  addDestinatario(destinatario: PessoaEntity) {

    if (!this.destinatarios)
      this.destinatarios = [];

    let itens = this.destinatarios;
    itens.push(destinatario);
    this.destinatarios = itens;
    destinatario.selecionado = true;
  }

  removeDestinatario(destinatario: PessoaEntity) {
    let itens = this.destinatarios;
    var idx = itens.findIndex(x => x.codigoDestinatarioWeb === destinatario.codigoDestinatarioWeb);
    itens.splice(idx, 1);
    this.destinatarios = itens;
    destinatario.selecionado = false;
  }

  montarListaPessoas(pessoas: PessoaEntity[]): PessoaEntity[] {

    let lista: Array<PessoaEntity> = [];

    for (var x = 0; x < pessoas.length; x++) {
      pessoas[x].codigoDestinatarioWeb = pessoas[x].codigoPessoa;
      let pessoa: PessoaEntity;
      if (this.session.usuario.acessoNovo) {
        pessoa = this.destinatarios.find(obj => obj.codigoPessoa === pessoas[x].codigoPessoa);
        if (pessoa)
          pessoa.codigoDestinatarioWeb = pessoa.codigoPessoa;
      }
      else
        pessoa = this.destinatarios.find(obj => obj.codigoDestinatarioWeb === pessoas[x].codigoDestinatarioWeb);

      if (pessoa) {
        pessoa.selecionado = true;
        lista.push(pessoa);
      }
      else {
        lista.push(pessoas[x]);
      }
    }
    console.log(lista);
    return lista;


  }

  filtrarPessoa(request: ConsultarPessoaRequestEntity): Observable<PessoaEntity[]> {
    // Convert entity to queryString
    const query = this.common.entityToQuery(request);
    console.log(query);
    return this.http.get<PessoaEntity[]>(`${environment.baseUrl}api/v2/destinatario/filtro/${query}`);
  }
}
