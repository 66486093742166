import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { take } from 'rxjs/operators';
import { ConsultarProdutoRequestEntity } from '../../entities/Requests/consultar-produto-request.entity';
import { ProdutoEntity } from '../../entities/produto.entity';
import { ComponentBase } from '../../shared/component-base';
import { SessionService } from '../../shared/session.service';
import { FacetProdutoEntity } from '../entities/facet-produto.entity';
import { ConsultarTagsFacetRequestEntity } from '../entities/requests/consultar-facet-request.entity';
import { ProdutoService } from '../produto.service';

@Component({
  selector: 'app-produto-facet',
  templateUrl: './produto-facet.component.html',
  styleUrls: ['./produto-facet.component.css']
})
export class ProdutoFacetComponent extends ComponentBase implements OnInit {
  request: ConsultarTagsFacetRequestEntity = new ConsultarTagsFacetRequestEntity();
  facets: FacetProdutoEntity[] = [];
  facetSelecionadas: FacetProdutoEntity[] = [];
  disponivel: boolean = false;
  pesquisando: boolean = true;
  somentoOportunidades: boolean = false;
  @Output() onFilterProducts: EventEmitter<ProdutoEntity[]> = new EventEmitter();

  constructor(
    public session: SessionService,
    private produtoService: ProdutoService,
  ) {
    super();
  }

  ngOnInit() {
    this.request.divisoes = this.session.getIdDivisoes();
    if (this.session.empresa)
      this.request.empresas = this.session.empresa.idCliente.toString();

    this.request.tags = [];
    this.consultarTags();
  }

  consultarTags() {
    //
    this.request.saldoZerado = this.disponivel;
    this.call2<FacetProdutoEntity[]>(() => this.produtoService.consultarTagsFacet(this.request))
      .pipe(take(1))
      .subscribe(resp => {
        console.log(resp);
        this.facets = resp;
        this.pesquisando = false;
        this.facets.forEach(facet => {
          facet.isOpen = true;
          facet.subCategorias.forEach(sub => {
            if (this.facetSelecionadas.find(x => x.idLink === sub.idLink)) {
              sub.selected = true;
              facet.isOpen = true;
            }
          });
        });
      });
  }

  toggle(idx: number) {
    const facets = this.session.facetProduto;
    facets[idx].isOpen = !facets[idx].isOpen;
    this.session.facetProduto = facets;
  }

  filter(item: FacetProdutoEntity) {

    let request = new ConsultarProdutoRequestEntity();
    request.saldoZerado = this.disponivel;
    request.idUsuario = this.session.usuario['idPessoa'];
    request.status = 0;
    request.estoqueMinimo = false;
    //request.somenteComEstoqueVirtual = !this.possuiAcessoRecurso(23);
    request.somentoOportunidades = this.somentoOportunidades;

    if (item.idCategoria === -3)
      request.divisoes = [item.idLink];
    else
      request.divisoes = this.session.getIdDivisoes();

    if (item.idCategoria === -4)
      request.empresas = item.idLink.toString();
    else
      request.empresas = this.session.getIdsEmpresa();

    if (item.idCategoria === -2)
      request.grupoProduto = item.idLink;

    if (item.idCategoria === -1)
      request.idLinhaProduto = item.idLink;

    if (item.idCategoria > 0) {
      request.idTagProduto = item.idLink;
    }

    if (this.session.termoPesquisa)
      request.pesquisaGenerica = this.session.termoPesquisa;

    // item.selected = !item.selected;
    // if (item.selected)
    //   this.updateFacets(item);
    // else
    //   this.removeFacets(item);

    this.loading2<any>(() => this.produtoService.consultarProdutosComFiltro(request))
      .pipe(take(1))
      .subscribe(response => {

        response.item1.forEach(x => {
          x.isOpen = true;
        });
        this.onFilterProducts.emit(response);
      });
  }

  removeFacets(item: FacetProdutoEntity) {
    if (item.idCategoria === -1) {
      if (this.session.empresa)
        this.request.empresas = this.session.empresa.idCliente.toString();
      else
        this.request.empresas = null;
    }

    if (item.idCategoria === -2)
      this.request.linhaProduto = null;

    if (item.idCategoria === -3) {
      this.request.divisoes = this.session.getIdDivisoes();
    }

    if (item.idCategoria === -4)
      this.request.categoriaProduto = null;

    if (item.idCategoria > 0) {
      const idx = this.request.tags.findIndex(x => x === item.idLink);
      this.request.tags.splice(idx, 1);
    }

    const idx = this.facetSelecionadas.findIndex(x => x.idLink === item.idLink);
    this.facetSelecionadas.splice(idx, 1);

    this.consultarTags();
  }

  updateFacets(item: FacetProdutoEntity) {
    if (item.idCategoria === -1)
      this.request.empresas = item.idLink.toString();

    if (item.idCategoria === -2)
      this.request.linhaProduto = item.idLink;

    if (item.idCategoria === -3)
      this.request.divisoes = [item.idLink];

    if (item.idCategoria === -4)
      this.request.categoriaProduto = item.idLink;

    if (item.idCategoria > 0) {
      this.request.tags.push(item.idLink);
    }

    this.facetSelecionadas.push(item);
    this.consultarTags();
  }

  filterDisponivel() {
    this.session.facetProduto = [];
    this.filter(new FacetProdutoEntity());
  }

  filterOportunidades() {
    this.session.facetProduto = [];

    this.filter(new FacetProdutoEntity());
  }
}
